
export const contactEmail = "soporte@officialcryptodex.com";

export const routes = {
    // homepage: "/",
    // faq: "/faq/",
    // giveaway: "/giveaway/",
    // support: "/support/",
    // company: "/company/",
    // assets: "/assets",
    
    welcomepage: "/",
    portfoliopage: "/portfolio",
    dashboardpage: "/dashboard",
    addcurrencypage: "/addcurrency",
    walletspage: "/wallets",
    historypage: "/history",
    securebackuppage: "/securebackup",
    settingspage: "/settings",
    restorewalletpage: "/restorewallet",
    currencypage: "/currency",
    accountsupportpage: "/accountsupport",
    account: "/dashboard/account",

    loginpage: "/login",
    //login2fa: "/account/login/2fa/",
    signup: "/signup",
    forgotten: "/forgotten",
    logout: "/logout",
};